import styled from 'styled-components';

export const Card = styled.div`
  ${({ theme }) => `
    display: grid;
    background: ${theme.colors.background.default};
    border-radius:  ${theme.radii.lg};
    padding:  ${theme.spacing.xl};
    gap:  ${theme.spacing.xl};
    max-width: 424px;
    margin: 0 auto;
  `}
`;

export const Navbar = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing.xxxs} ${theme.spacing.sidesDesktop};
    background: ${theme.colors.background.default};
    border-bottom: 1px solid ${theme.colors.border.default};
    border-radius: ${theme.radii.xs};
  `}
`;

export const PageWrapper = styled.div`
  min-height: calc(100vh - 60px);
  display: grid;
  grid-template-rows: min-content;
`;
