import { FC, MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownMenuItem, MenuSection, SubMenu, Typography } from '@chooose/ui';
import styled from 'styled-components';
import { useIsHideContextSwitcherEnabled } from '@api/partnership/queries';
import { useCurrentAccount, useUser } from '@api/user/queries';
import { SupplierAccount } from '@api/user/types';
import { useCorporateContextSwitcher } from '@hooks/useCorporateContextSwitcher';
import { Key, User } from '@icons';

import { groupByKey, sortByKey, transformAccount } from './contextSwitcherUtils';

export type ContextElement = {
  id: string;
  isSelected?: boolean;
  portalName?: string;
  prefix?: string;
  suffix?: string;
  testId: string;
  type: AccountType;
  url?: string;
};
type ContextElementKeys = keyof ContextElement;

const StyledTitle = styled.span`
  gap: 4px;
  display: flex;
  align-items: center;
`;

const isDevelopment = import.meta.env.DEV;

const ContextSwitcher: FC = () => {
  const { t } = useTranslation('common');
  const { data: user } = useUser();
  const { account: currentAccount } = useCurrentAccount();
  const accounts = user?.accounts ?? [];
  const { switchContext } = useCorporateContextSwitcher();
  const personalOffsettingUrl = import.meta.env.REACT_APP_MYCHOOOSE_REDIRECT_URL;
  const isHideContextSwitcherEnabled = useIsHideContextSwitcherEnabled();

  const accountsArray = useMemo(() => {
    const portalAccounts = accounts.filter(({ type }) => type && type !== 'Supplier' && type !== 'MyChooose').map(transformAccount);

    const supplierAccounts = accounts
      .filter((acc): acc is SupplierAccount => acc.type === 'Supplier' && !!acc.context?.eamAppUrl && !!acc.context?.supplierName)
      .map(transformAccount);

    const individualAccount = accounts.find(({ type }) => type && type === 'MyChooose');
    const active = currentAccount && transformAccount(currentAccount);
    const notActive = sortByKey(
      groupByKey<ContextElement, ContextElementKeys>(
        [...portalAccounts, ...supplierAccounts].filter(({ id }) => id !== active?.id),
        'portalName',
      ),
    );

    return { active, notActive, individualAccount };
  }, [accounts]);

  const { active, notActive, individualAccount } = accountsArray || {};
  const notActiveArray = notActive && Object.keys(notActive);

  const handleLinkClick = useCallback((e: MouseEvent<HTMLButtonElement & { href?: string }>, contextElement: ContextElement) => {
    e.preventDefault();
    const { id, portalName } = contextElement;

    if (portalName === currentAccount?.context?.partnerPortalName) {
      switchContext(id);
    } else {
      let href = e.currentTarget?.href;

      if (!href) {
        return;
      }

      // NOTE(@pkgacek): Fix redirection while in development mode
      if (isDevelopment) {
        const hrefRemovedProtocol = href.replace(/^.*:\/\//i, '');
        const parsedHref = hrefRemovedProtocol.substring(hrefRemovedProtocol.indexOf('/') + 1);
        href = `${import.meta.env.BASE_URL}${parsedHref}`;
      }

      window.sessionStorage.clear();
      window.location.href = href as string;
    }
  }, []);

  if (!accountsArray || notActiveArray.length === 0 || !isHideContextSwitcherEnabled) {
    return null;
  }

  if (!active) {
    return null;
  }

  return (
    <MenuSection data-testid='settings-menu-active-profile' title={t('contextSwitcher.activeProfile')}>
      <DropdownMenuItem
        data-testid='settings-menu-active-profile-item'
        icon={<Key weight='bold' />}
        onClick={e => !active.isSelected && handleLinkClick(e, active)}
        title={
          <StyledTitle>
            <Typography data-testid='active-profile-prefix' tag='span'>
              {t(active.prefix ?? '')}
            </Typography>
            /
            <Typography data-testid='active-profile-suffix' tag='span' variant='secondary' isHighlight>
              {active.suffix}
            </Typography>
          </StyledTitle>
        }
        variant='secondary'>
        <SubMenu data-testid='settings-sub-menu'>
          {notActiveArray &&
            notActiveArray.length !== 0 &&
            notActiveArray.map((key, index) => {
              return (
                <MenuSection data-testid={`settings-sub-menu-section-${index}`} title={`${key}`} key={`${key}-${index}`}>
                  {Object.keys(notActive[key]).map((a, index) => {
                    const { prefix, suffix, url, type } = notActive[key][a as unknown as number];

                    return (
                      <DropdownMenuItem
                        data-type={type}
                        data-testid={`settings-sub-menu-item-${type}`}
                        key={`${prefix}-${suffix}-${index}`}
                        onClick={e => handleLinkClick(e, notActive[key][a as unknown as number])}
                        to={url}
                        variant='secondary'
                        title={
                          <StyledTitle>
                            {prefix && (
                              <Typography data-testid={`settings-sub-menu-item-${type}-prefix`} tag='span'>
                                {t(prefix)} /{' '}
                              </Typography>
                            )}

                            <Typography data-testid={`settings-sub-menu-item-${type}-suffix`} tag='span' variant='secondary' isHighlight>
                              {suffix}
                            </Typography>
                          </StyledTitle>
                        }
                      />
                    );
                  })}
                </MenuSection>
              );
            })}
        </SubMenu>
      </DropdownMenuItem>

      {individualAccount && personalOffsettingUrl && (
        <DropdownMenuItem
          data-testid={`settings-menu-item-personal-off-settings`}
          icon={<User weight='bold' />}
          title={t('contextSwitcher.personalOffsetting')}
          to={personalOffsettingUrl}
          variant='secondary'
        />
      )}
    </MenuSection>
  );
};

export default ContextSwitcher;
