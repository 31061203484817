import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import qs from 'qs';
import { useCustomer } from '@api/customer/queries';
import { NetworkError, UNAUTHORIZED_ERROR_CODE } from '@api/error';
import { usePartnership } from '@api/partnership.queries';
import { usePartnershipSettings } from '@api/partnership/queries';
import { Settings } from '@api/partnership/types';
import { useCurrentAccount, useUser } from '@api/user/queries';
import { CustomerAccount, PartnerAccount, TransactAccount, User } from '@api/user/types';
import { LoaderFullPage } from '@components/Loader';
import { LogoutRedirect } from '@components/LogoutRedirect/LogoutRedirect';
import { RootState } from '@store';
import { getRoles } from '@store/allUsers/allUsersSlice';
import {
  setContext,
  setFeatures,
  setFeeDisplay,
  setLanguages,
  setLightLogo,
  setLogo,
  setOffsetTypesWithoutSaf,
  setPartnershipCurrency,
  setPartnershipId,
} from '@store/app/appSlice';
import { useAccountIdStore } from '@store/context';
import { setMultiplier } from '@store/orders/ordersSlice';
import { PartnerSettings, setPartnerSettings } from '@store/partnerSettings/partnerSettingsSlice';
import { setSubscriptions } from '@store/subscriptions/subscriptionsSlice';
import { loadAttributionForCustomer, loadAttributionForPartnership, loadUserAttributionDataForWeChooose } from '@store/user/attributionSlice';
import { setCurrentAccount, setCustomer, setIsAuthorizationError, setPartnership, setUser, setUserLoading } from '@store/user/userSlice';
import { isCustomerAccount, isPartnerAccount, isTransactAccount } from '@utils/contextChecker';
import { defineAbilityFor } from '@utils/permissions/defineAbility';

import { getCurrentAccountAndContext } from './getCurrentAccountAndContext';
import { RedirectIntent } from './types';

export const AccountInitializer: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: user, isLoading: isUserLoading, isError, error } = useUser();
  const { data: settings, isLoading: isSettingsLoading } = usePartnershipSettings();
  const { accountId: savedAccountId, setAccountId } = useAccountIdStore();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const { inProgress } = useMsal();

  const intent = getIntentFromSearch(location.search);
  intent.accountId ??= savedAccountId;

  useEffect(
    function handleUnauthorizedError() {
      if (isError && error instanceof NetworkError && error.message === UNAUTHORIZED_ERROR_CODE) {
        sessionStorage.setItem('loginError', 'true');
        dispatch(setIsAuthorizationError(true));
        history.replace('/login-error');
      }
    },
    [error, isError, history],
  );

  useEffect(() => {
    if (inProgress === 'none' && !ready) {
      setReady(true);
    }
  }, [inProgress]);

  if (!ready || isUserLoading || isSettingsLoading || isError) {
    return <LoaderFullPage />;
  }

  if (!user || !settings) {
    throw new Error('User or settings not found');
  }

  const result = getCurrentAccountAndContext(user, settings, intent);

  if (result.logout) {
    return <LogoutRedirect currentPartner={result.partnerPortalName} />;
  }

  if (result.redirect) {
    return <LoaderFullPage />;
  }

  if (!result.account && !result.context) {
    throw new Error('Could not get current account and context from user and settings.');
  }

  if (result.account.id !== savedAccountId) {
    setAccountId(result.account.id);
  }

  return (
    <>
      {isCustomerAccount(result.account) && (
        <CustomerReduxSyncer user={user} currentAccount={result.account} portalSettings={settings}>
          {children}
        </CustomerReduxSyncer>
      )}
      {isTransactAccount(result.account) && (
        <TransactReduxSyncer user={user} currentAccount={result.account} portalSettings={settings}>
          {children}
        </TransactReduxSyncer>
      )}
      {isPartnerAccount(result.account) && (
        <ConnectReduxSyncer user={user} currentAccount={result.account} portalSettings={settings}>
          {children}
        </ConnectReduxSyncer>
      )}
    </>
  );
};

interface BaseReduxSyncerProps {
  user: User;
  portalSettings: Settings;
}

interface CustomerReduxSyncerProps extends BaseReduxSyncerProps {
  currentAccount: CustomerAccount;
}

// TODO: migrate dispatches one by one to react query / zustand
const CustomerReduxSyncer: FC<PropsWithChildren<CustomerReduxSyncerProps>> = ({ user, currentAccount: account, portalSettings, children }) => {
  const { data: customer, isLoading: isCustomerLoading, isError, error } = useCustomer(account.customerId);
  const { account: currentAccount } = useCurrentAccount();
  const ability = useMemo(() => defineAbilityFor(currentAccount), [currentAccount]);
  const canGetRoles = ability.can('view', 'Authorization');

  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, accounts } = useSelector((state: RootState) => state.user);

  useEffect(
    function handleUnauthorizedError() {
      if (isError && error instanceof NetworkError && error.message === UNAUTHORIZED_ERROR_CODE) {
        sessionStorage.setItem('loginError', 'true');
        dispatch(setIsAuthorizationError(true));
        history.replace('/login-error');
      }
    },
    [error, isError, history],
  );

  useEffect(
    function syncRedux() {
      if (!customer) {
        return;
      }

      dispatch(setContext('wechooose'));
      // TODO: this is a temporary solution as new types are not compatible
      // with old ones.
      dispatch(setUser(user as unknown as UserLegacy));
      dispatch(setCurrentAccount(account as unknown as UserLegacy['accounts'][0]));
      dispatch(setCustomer(customer as unknown as CustomerLegacy));
      dispatch(setFeatures({ features: portalSettings.features, context: 'wechooose' }));
      dispatch(setLogo(portalSettings.logoUrl));
      dispatch(setLightLogo(portalSettings.lightLogoUrl));
      dispatch(setLanguages(portalSettings.language ? portalSettings.language : []));
      dispatch(setFeeDisplay(portalSettings.feeDisplay));
      dispatch(setOffsetTypesWithoutSaf(portalSettings.safFeatures ?? []));
      dispatch(setPartnershipCurrency(portalSettings.partnerCurrency));
      if (portalSettings.partnershipId) {
        dispatch(setPartnershipId(portalSettings.partnershipId));
      }

      dispatch(loadAttributionForCustomer(account.customerId));
      dispatch(loadUserAttributionDataForWeChooose());
      canGetRoles && dispatch(getRoles());
      dispatch(
        setPartnerSettings({
          ...portalSettings,
          logoUrl: portalSettings?.logoUrl ?? '',
          lightLogoUrl: portalSettings?.lightLogoUrl ?? '',
          colors: portalSettings?.colors,
          faviconUrl: portalSettings?.faviconUrl ?? '',
          partnershipId: portalSettings?.partnershipId ?? '',
          currencies: portalSettings?.currencies || [],
          disableSignupLink: (portalSettings?.features || []).includes('DisableSignUp'),
        } as unknown as PartnerSettings),
      );
      if (portalSettings.multiplierSettings) {
        dispatch(setMultiplier(portalSettings.multiplierSettings));
      }

      dispatch(setSubscriptions((customer as unknown as CustomerLegacy)?.subscriptions ?? []));

      sessionStorage.removeItem('loginError');
      dispatch(setIsAuthorizationError(false));
      dispatch(setUserLoading(false));
    },
    [customer?.id],
  );

  const isUserLoading = (loading && !accounts) || isCustomerLoading;

  if (isUserLoading || isError) {
    return <LoaderFullPage />;
  }

  return <> {children} </>;
};

interface TransactReduxSyncerProps extends BaseReduxSyncerProps {
  currentAccount: TransactAccount;
}

const TransactReduxSyncer: FC<PropsWithChildren<TransactReduxSyncerProps>> = ({ user, currentAccount: account, portalSettings, children }) => {
  const { data: customer, isLoading: isCustomerLoading, isError, error } = useCustomer(account.customerId);
  const dispatch = useDispatch();
  const history = useHistory();
  const { account: currentAccount } = useCurrentAccount();
  const ability = useMemo(() => defineAbilityFor(currentAccount), [currentAccount]);
  const canGetRoles = ability.can('view', 'Authorization');

  useEffect(
    function handleUnauthorizedError() {
      if (isError && error instanceof NetworkError && error.message === UNAUTHORIZED_ERROR_CODE) {
        sessionStorage.setItem('loginError', 'true');
        dispatch(setIsAuthorizationError(true));
        history.replace('/login-error');
      }
    },
    [error, isError, history],
  );

  useEffect(
    function syncRedux() {
      if (!customer) {
        return;
      }

      dispatch(setContext('transact'));
      dispatch(setUser(user as unknown as UserLegacy));
      dispatch(setCurrentAccount(account as unknown as UserLegacy['accounts'][0]));
      dispatch(setCustomer(customer as unknown as CustomerLegacy));
      dispatch(setFeatures({ features: customer?.settings?.portal?.transactFeatures ?? portalSettings.transactFeatures, context: 'transact' }));
      dispatch(setLogo(portalSettings.logoUrl));
      dispatch(setLightLogo(portalSettings.lightLogoUrl));
      dispatch(setLanguages(portalSettings.language ? portalSettings.language : []));
      dispatch(setFeeDisplay(portalSettings.feeDisplay));
      dispatch(setPartnershipCurrency(portalSettings.partnerCurrency));

      dispatch(
        setPartnerSettings({
          ...portalSettings,
          logoUrl: portalSettings?.logoUrl ?? '',
          lightLogoUrl: portalSettings?.lightLogoUrl ?? '',
          colors: portalSettings?.colors,
          faviconUrl: portalSettings?.faviconUrl ?? '',
          partnershipId: portalSettings?.partnershipId ?? '',
          currencies: portalSettings?.currencies || [],
        } as unknown as PartnerSettings),
      );

      if (portalSettings.partnershipId) {
        dispatch(setPartnershipId(portalSettings.partnershipId));
      }

      canGetRoles && dispatch(getRoles());

      sessionStorage.removeItem('loginError');
      dispatch(setIsAuthorizationError(false));
      dispatch(setUserLoading(false));
    },
    [customer?.id],
  );

  const isUserLoading = !user.accounts || isCustomerLoading;

  if (isUserLoading || isError) {
    return <LoaderFullPage />;
  }

  return <> {children} </>;
};

interface ConnectReduxSyncerProps extends BaseReduxSyncerProps {
  currentAccount: PartnerAccount;
}

const ConnectReduxSyncer: FC<PropsWithChildren<ConnectReduxSyncerProps>> = ({ user, currentAccount: account, portalSettings, children }) => {
  const { data: partnership, isLoading: isPartnershipLoading, isError, error } = usePartnership(account.partnershipId);

  let partnershipId: string | undefined = portalSettings.partnershipId;
  if (partnershipId !== account.partnershipId) {
    partnershipId = account.partnershipId;
  }
  const { data: settings } = usePartnershipSettings(partnershipId);
  const dispatch = useDispatch();
  const { loading, accounts } = useSelector((state: RootState) => state.user);
  const history = useHistory();

  useEffect(
    function handleUnauthorizedError() {
      if (isError && error instanceof NetworkError && error.message === UNAUTHORIZED_ERROR_CODE) {
        sessionStorage.setItem('loginError', 'true');
        dispatch(setIsAuthorizationError(true));
        history.replace('/login-error');
      }
    },
    [error, isError, history],
  );

  useEffect(
    function syncRedux() {
      if (!partnership || !settings) {
        return;
      }
      dispatch(setContext('connect'));
      // TODO: this is a temporary solution as new types are not compatible
      // With old ones.
      dispatch(setUser(user as unknown as UserLegacy));
      dispatch(setCurrentAccount(account as unknown as UserLegacy['accounts'][0]));
      dispatch(setPartnership(partnership));
      dispatch(setFeatures({ features: settings.connectFeatures ?? [], context: 'connect' }));
      dispatch(setLogo(settings.logoUrl));
      dispatch(setLightLogo(settings.lightLogoUrl));
      dispatch(setLanguages(settings.language ? settings.language : []));
      dispatch(setFeeDisplay(settings.feeDisplay));
      dispatch(setOffsetTypesWithoutSaf(settings.safFeatures ?? []));
      dispatch(setPartnershipCurrency(settings.partnerCurrency));

      dispatch(
        setPartnerSettings({
          ...settings,
          logoUrl: settings?.logoUrl ?? '',
          lightLogoUrl: settings?.lightLogoUrl ?? '',
          colors: settings?.colors,
          faviconUrl: settings?.faviconUrl ?? '',
          partnershipId: settings?.partnershipId ?? '',
          currencies: settings?.currencies || [],
        } as unknown as PartnerSettings),
      );

      if (settings.partnershipId) {
        dispatch(setPartnershipId(settings.partnershipId));
      }
      dispatch(loadAttributionForPartnership(account.partnershipId ?? ''));

      sessionStorage.removeItem('loginError');
      dispatch(setIsAuthorizationError(false));
      dispatch(setUserLoading(false));
    },
    [partnership?.id, settings],
  );

  const isUserLoading = (loading && !accounts) || isPartnershipLoading;

  if (isUserLoading || isError) {
    return <LoaderFullPage />;
  }

  return <> {children} </>;
};

function getIntentFromSearch(search: string): RedirectIntent {
  return qs.parse(search.replace('?', ''));
}
