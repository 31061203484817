/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* @typescript-eslint/ban-ts-ignore */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import createUserApi from '@api/user';
import { AppThunk, RootState } from '@store';
import { appContextSelector, partnershipCurrencySelector } from '@store/app/appSlice';
import { showToast } from '@store/app/toasterSlice';
import { predefinedMetadataNames } from '@utils/metadata';
import { getEnvironment } from '@utils/tags';

interface State extends Partial<UserLegacy> {
  loading: boolean;
  currentAccount?: AccountLegacy;
  isAuthorizationError?: boolean;
  isNetworkError?: boolean;
}
const initialState: State = {
  loading: true,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: State, action: PayloadAction<UserLegacy>): State => {
      return { ...state, ...action.payload };
    },
    setCustomer: (state: State, action: PayloadAction<CustomerLegacy>): State => {
      state.currentAccount!.customer = action.payload;
      const currentAccountIdx = state.accounts?.findIndex(a => a.customerId === action.payload.id) as number;
      state.accounts![currentAccountIdx].customer = action.payload;

      return state;
    },

    setPartnership: (state: State, action: PayloadAction<PartnershipLegacy>): State => {
      state.currentAccount!.partnership = action.payload;
      const currentAccountIdx = state.accounts?.findIndex(a => a.partnershipId === action.payload.id) as number;
      state.accounts![currentAccountIdx].partnership = action.payload;

      return state;
    },
    setLoading: (state: State, action: PayloadAction<boolean>): State => {
      state.loading = action.payload;

      return state;
    },
    setCurrentAccount: (state: State, action: PayloadAction<AccountLegacy>): State => {
      state.currentAccount = action.payload;

      return state;
    },
    updateAccounts: (state: State, action: PayloadAction<AccountLegacy[]>): State => {
      state.accounts = action.payload;

      return state;
    },
    updateProfile: (state: State, action: PayloadAction<Profile>): State => {
      state.currentAccount!.profile = action.payload;
      const currentAccountIdx = state.accounts?.findIndex(a => a.customerId === state.currentAccount?.customerId) as number;
      state.accounts![currentAccountIdx].profile = action.payload;

      return state;
    },
    clearMetadata: (state: State): State => {
      state.currentAccount!.customer = { ...state.currentAccount!.customer!, customMetadata: [] };

      return state;
    },
    clearSingleMetadata: (state: State, action: PayloadAction<string | string[]>): State => {
      let filteredMetadata = [];
      if (Array.isArray(action.payload)) {
        filteredMetadata =
          state.currentAccount!.customer?.customMetadata?.filter(m => !(action.payload as string[]).some((p: string) => p === m.internalName)) ?? [];
      } else {
        filteredMetadata = state.currentAccount!.customer?.customMetadata?.filter(m => m.internalName !== action.payload) ?? [];
      }
      state.currentAccount!.customer = { ...state.currentAccount!.customer!, customMetadata: filteredMetadata };

      return state;
    },
    setNewOwner: (state: State, action: PayloadAction<Owner>): State => {
      state.currentAccount!.customer!.owner = action.payload;
      const currentAccountIdx = state.accounts?.findIndex(a => a.customerId === state.currentAccount?.customerId) as number;
      state.accounts![currentAccountIdx].customer!.owner = action.payload;

      return state;
    },
    setEmailSubscription: (state: State, action: PayloadAction<MailNotifications>): State => {
      state.currentAccount!.mailNotifications = action.payload;

      return state;
    },
    changeCustomer: (state: State, action: PayloadAction<CustomerLegacy>): State => {
      const account = state.accounts?.find(a => a.customerId === action.payload.id);
      if (!account) {
        return state;
      }
      account.customer = action.payload;
      state.currentAccount = account;
      state.accounts = state.accounts?.filter(a => a.id !== account.id);
      state.accounts = [...state.accounts!, account];

      return state;
    },
    updateCustomerAddress: (state: State, action: PayloadAction<{ id: string; customerInformation: CustomerInformation }>): State => {
      state.currentAccount!.customer!.info = action.payload.customerInformation;
      const currentAccountIdx = state.accounts?.findIndex(a => a.customerId === state.currentAccount?.customerId) as number;
      state.accounts![currentAccountIdx].customer!.info = action.payload.customerInformation;

      return state;
    },
    updateCustomerLanguage: (state: State, action: PayloadAction<{ language: string }>): State => {
      state.currentAccount!.profile!.language = action.payload.language;
      const currentAccountIdx = state.accounts?.findIndex(a => a.customerId === state.currentAccount?.customerId) as number;
      state.accounts![currentAccountIdx].profile!.language = action.payload.language;

      return state;
    },
    changePartnership: (state: State, action: PayloadAction<PartnershipLegacy>): State => {
      const account = state.accounts?.find(a => a.partnershipId === action.payload.id);
      if (!account) {
        return state;
      }
      account.partnership = action.payload;
      state.currentAccount = account;
      state.accounts = state.accounts?.filter(a => a.id !== account.id);
      state.accounts = [...state.accounts!, account];

      return state;
    },

    setIsAuthorizationError: (state: State, action: PayloadAction<boolean>): State => {
      state.isAuthorizationError = action.payload;

      return state;
    },

    setIsNetworkError: (state: State, action: PayloadAction<boolean>): State => {
      state.isNetworkError = action.payload;

      return state;
    },
  },
});

const { setUser, setCustomer, setPartnership, setLoading, setCurrentAccount, setNewOwner, updateCustomerAddress, setIsAuthorizationError } =
  slice.actions;

export { setCurrentAccount, setCustomer, setIsAuthorizationError, setPartnership, setUser, setLoading as setUserLoading };

export default slice.reducer;

export const deleteCustomer =
  (onSuccess: CallableFunction, onError: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const { currentAccount } = getState().user;
      const context = appContextSelector(getState());
      const userApi = createUserApi(context);
      const id = currentAccount?.customerId ?? '';

      dispatch(setLoading(true));

      await userApi.deleteCustomer(id);
      dispatch(setLoading(false));
      onSuccess();
    } catch {
      dispatch(setLoading(false));
      onError();
    }
  };

export const transferOwnership =
  (id: string, onError: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const { currentAccount } = getState().user;
      const context = appContextSelector(getState());
      const userApi = createUserApi(context);
      dispatch(setLoading(true));

      const newOwner = await userApi.transferOwnership(currentAccount?.customerId ?? '', id);
      dispatch(setNewOwner(newOwner as Owner));
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
      onError();
    }
  };

export const updateCustomerData =
  (newData: CustomerInformation, onSuccess?: CallableFunction, onError?: CallableFunction): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const userApi = createUserApi(context);
    const currentAccount = currentUserSelector(getState());
    const customerId = currentAccount?.customerId ?? '';
    dispatch(setLoading(true));

    try {
      const result = await userApi.updateCustomerInformation(newData, customerId);
      dispatch(updateCustomerAddress({ id: customerId, customerInformation: result }));
      dispatch(setLoading(false));
      onSuccess && onSuccess();
    } catch (err) {
      dispatch(setLoading(false));
      onError && onError();
    }
  };

export const getChannelGroupName = (group: string): string => group.replaceAll('wechooose', 'portal');

export const resendCustomerInvite =
  (partnershipId: string, customerId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const userApi = createUserApi(context);
    try {
      await userApi.resendCustomerInvite(partnershipId, customerId);

      dispatch(
        showToast({ variant: 'success', titleI18nKey: 'settings:invite.successTitle', descriptionI18nKey: 'settings:invite.successDescription' }),
      );
    } catch {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'common:errorDescription' }));
    }
  };

export const resendUserInvite =
  (inviteId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const context = appContextSelector(getState());
    const userApi = createUserApi(context);
    try {
      await userApi.resendUserInvite(inviteId);

      dispatch(
        showToast({ variant: 'success', titleI18nKey: 'settings:invite.successTitle', descriptionI18nKey: 'settings:invite.successDescription' }),
      );
    } catch {
      dispatch(showToast({ variant: 'error', titleI18nKey: 'common:errorTitle', descriptionI18nKey: 'common:errorDescription' }));
    }
  };

export const transactionsPerChannelForCurrentWeek = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.thisWeek?.countPerChannel;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.count } : { name: getChannelGroupName(channel.group), value: channel.count },
    ),
);

export const transactionsCO2PerChannelForCurrentWeek = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.thisWeek?.co2PerChannel;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.kilosCo2 } : { name: getChannelGroupName(channel.group), value: channel.kilosCo2 },
    ),
);

export const transactionsPerChannelForLastWeek = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.lastWeek?.countPerChannel;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.count } : { name: getChannelGroupName(channel.group), value: channel.count },
    ),
);

export const transactionsCO2PerChannelForLastWeek = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.lastWeek?.co2PerChannel;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.kilosCo2 } : { name: getChannelGroupName(channel.group), value: channel.kilosCo2 },
    ),
);

export const transactionsPerChannelForCurrentMonth = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.thisMonth?.countPerChannel;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.count } : { name: getChannelGroupName(channel.group), value: channel.count },
    ),
);

export const transactionsCO2PerChannelForCurrentMonth = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.thisMonth?.co2PerChannel;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.kilosCo2 } : { name: getChannelGroupName(channel.group), value: channel.kilosCo2 },
    ),
);

export const transactionsPerChannelForLastMonth = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.lastMonth?.countPerChannel;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.count } : { name: getChannelGroupName(channel.group), value: channel.count },
    ),
);

export const transactionsCO2PerChannelForLastMonth = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.lastMonth?.co2PerChannel;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.kilosCo2 } : { name: getChannelGroupName(channel.group), value: channel.kilosCo2 },
    ),
);

export const transactionsPerChannelForCurrentYear = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.thisYear?.countPerChannel;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.count } : { name: getChannelGroupName(channel.group), value: channel.count },
    ),
);

export const transactionsCO2PerChannelForCurrentYear = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.thisYear?.co2PerChannel;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.kilosCo2 } : { name: getChannelGroupName(channel.group), value: channel.kilosCo2 },
    ),
);

export const transactionsPerChannelForLastYear = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.lastYear?.countPerChannel;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.count } : { name: getChannelGroupName(channel.group), value: channel.count },
    ),
);

export const transactionsCO2PerChannelForLastYear = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.lastYear?.co2PerChannel;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.kilosCo2 } : { name: getChannelGroupName(channel.group), value: channel.kilosCo2 },
    ),
);

export const transactionsOverTimeForCurrentWeek = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.thisWeek?.countPerTimeUnit;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.count } : { name: getChannelGroupName(channel.group), value: channel.count },
    ),
);

export const transactionsCO2OverTimeForCurrentWeek = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.thisWeek?.co2PerTimeUnit;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.kilosCo2 } : { name: getChannelGroupName(channel.group), value: channel.kilosCo2 },
    ),
);

export const transactionsOverTimeForLastWeek = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.lastWeek?.countPerTimeUnit;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.count } : { name: getChannelGroupName(channel.group), value: channel.count },
    ),
);

export const transactionsCO2OverTimeForLastWeek = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.lastWeek?.co2PerTimeUnit;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.kilosCo2 } : { name: getChannelGroupName(channel.group), value: channel.kilosCo2 },
    ),
);

export const transactionsOverTimeForCurrentQuarter = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.thisQuarter?.countPerTimeUnit;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.count } : { name: getChannelGroupName(channel.group), value: channel.count },
    ),
);

export const transactionsCO2OverTimeForCurrentQuarter = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.thisQuarter?.co2PerTimeUnit;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.kilosCo2 } : { name: getChannelGroupName(channel.group), value: channel.kilosCo2 },
    ),
);

export const transactionsOverTimeForLastQuarter = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.lastQuarter?.countPerTimeUnit;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.count } : { name: getChannelGroupName(channel.group), value: channel.count },
    ),
);

export const transactionsCO2OverTimeForLastQuarter = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.lastQuarter?.co2PerTimeUnit;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.kilosCo2 } : { name: getChannelGroupName(channel.group), value: channel.kilosCo2 },
    ),
);

export const transactionsOverTimeForCurrentYear = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.thisYear?.countPerTimeUnit;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.count } : { name: getChannelGroupName(channel.group), value: channel.count },
    ),
);

export const transactionsCO2OverTimeForCurrentYear = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.thisYear?.co2PerTimeUnit;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.kilosCo2 } : { name: getChannelGroupName(channel.group), value: channel.kilosCo2 },
    ),
);

export const transactionsOverTimeForLastYear = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.lastYear?.countPerTimeUnit;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.count } : { name: getChannelGroupName(channel.group), value: channel.count },
    ),
);

export const transactionsCO2OverTimeForLastYear = createSelector(
  (state: RootState) => {
    const attribution = state.attribution;
    if (attribution && attribution.statistics) {
      return attribution.statistics.facilitated?.lastYear?.co2PerTimeUnit;
    }

    return [];
  },
  channels =>
    channels?.map(channel =>
      !channel.group ? { name: 'Other', value: channel.kilosCo2 } : { name: getChannelGroupName(channel.group), value: channel.kilosCo2 },
    ),
);

export const currentUserSelector = (state: RootState): AccountLegacy | undefined => state.user.currentAccount;

export const powerBiReportIdSelector = (state: RootState): string | undefined => {
  const isConnectContext = state.app.context === 'connect';
  const env = getEnvironment();

  const defaultCustomerReportId =
    import.meta.env.REACT_APP_DEFAULT_CUSTOMER_ANALYTICS_POWERBI_REPORT ??
    (env === 'production'
      ? // Those hardcoded values should be deleted after doing a migration for all pipelines
        '82aea492-b471-4413-b4c1-79cc096ea7dd'
      : '4fe61094-ef75-490c-a398-5bb7c7938c5a');
  const customerReportId = state.user.currentAccount?.customer?.settings?.powerBi?.reportId ?? defaultCustomerReportId;
  const defaultPartnerReportId = import.meta.env.REACT_APP_DEFAULT_ANALYTICS_POWERBI_REPORT;
  const partnerReportId = state.user.currentAccount?.partnership?.settings?.powerBi?.reportId ?? defaultPartnerReportId;

  const result = isConnectContext ? partnerReportId : customerReportId;

  return result;
};

export const customerOwnerSelector = (state: RootState): Owner | undefined => state.user.currentAccount?.customer?.owner;

export const isCurrentUserOwner = (state: RootState): boolean => {
  const id = state.user.currentAccount?.customer?.owner?.userAccountId;

  return id ? id === state.user.currentAccount?.id : false;
};

export const currentUserRoleIdSelector = (state: RootState): string | undefined => {
  if (state.user.currentAccount && state.user.currentAccount.customerRoles.length) {
    return state.user.currentAccount.customerRoles[0].customerRoleId;
  }

  return undefined;
};

export const currentUserCountryCodeSelector = (state: RootState): string | undefined =>
  state.user.currentAccount?.customer?.info?.address?.countryCodeIso2;

export const currentProfileSelector = (state: RootState): Profile | undefined => state.user.currentAccount?.profile;

export const metadataSelector = createSelector(
  (state: RootState) => state.user,
  user => user.currentAccount?.customer?.customMetadata,
);

export const customMetadataSelector = createSelector(
  (state: RootState) => state.user,
  user => user.currentAccount?.customer?.customMetadata.filter(({ internalName }) => !predefinedMetadataNames.includes(internalName)),
);

export const customerCurrencySelector = createSelector(
  (state: RootState) => state.user.currentAccount,
  partnershipCurrencySelector,
  (currentAccount, partnerCurrency) => currentAccount?.customer?.payment?.currency ?? partnerCurrency ?? 'USD',
);

export const userCurrencySelector = createSelector(
  (state: RootState) => state.user.currentAccount,
  (state: RootState) => state.app.context,
  customerCurrencySelector,
  (currentAccount, context, customerCurrency) => (context === 'connect' ? currentAccount?.partnership?.currency : customerCurrency) ?? 'USD',
);
