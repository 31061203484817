import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination, theme, Typography } from '@chooose/ui';

import licensesData from './licenses.json';

import * as s from './Licenses.styles';

const LIMIT = 25;

const Licenses = () => {
  const { t } = useTranslation('common');
  const [currentPage, setCurrentPage] = useState(1);
  const offset = (currentPage - 1) * LIMIT;

  const currentLicenses = Object.values(licensesData).slice(offset, offset + LIMIT);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <s.HeaderWrapper>
        <Typography data-testid='package-label' tag='h4'>
          {t('licenses.package')}
        </Typography>
        <Typography data-testid='copyright-label' tag='h4'>
          {t('licenses.copyright-and-license')}
        </Typography>
      </s.HeaderWrapper>

      {currentLicenses.map(license => (
        <s.LicenseWrapper key={license.name}>
          <Typography data-testid='license-name' variant='secondary' color={theme.colors.text.primary}>
            {license.name}
          </Typography>
          <s.LicenseText data-testid='license-text'>{license.licenseText}</s.LicenseText>
        </s.LicenseWrapper>
      ))}

      <s.PaginationContainer>
        <Pagination onPageChange={handlePageChange} currentPage={currentPage} totalItems={Object.values(licensesData).length} limit={LIMIT} />
      </s.PaginationContainer>
    </div>
  );
};

export default Licenses;
