export function getCountries(allCountries: Option[], customMetadata: CustomMetadata[]): Option[] {
  const countries =
    customMetadata
      .find(c => c.internalName === 'country')
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ?.listValues?.map(c => allCountries.find(a => a.value?.toLocaleLowerCase() === c?.toLocaleLowerCase())!) ?? [];

  return [...countries].sort((a, b) => a.label.localeCompare(b.label));
}
export const predefinedMetadataNames = [
  'country',
  'department',
  'description',
  'emissionScope',
  'timeFrameFrom',
  'timeFrameTo',
  'timeFrame',
  'poNumber',
  'costCenter',
  'orderName',
  'beneficiaryName',
];

export const getCustomMetadata = (metadata: CustomMetadata[]): CustomMetadata[] =>
  metadata?.filter(({ internalName }) => !predefinedMetadataNames.includes(internalName)) ?? [];

export const getMandatoryCustomMetadata = (metadata: CustomMetadata[]): CustomMetadata[] => {
  const customMetadata = getCustomMetadata(metadata);

  return customMetadata.filter(({ isMandatory }) => isMandatory);
};

export const getOptionalCustomMetadata = (metadata: CustomMetadata[]): CustomMetadata[] => {
  const customMetadata = getCustomMetadata(metadata);

  return customMetadata.filter(({ isMandatory }) => !isMandatory);
};

export const getDisplayName = (metadata: CustomMetadata[], internalName: string): string => {
  return metadata.find(m => m.internalName === internalName)?.displayName ?? '';
};
