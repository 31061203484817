import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Button, DropdownMenuItem, Menu, MenuSection } from '@chooose/ui';
import { useLogout } from '@lib/msal/useLogout';
import { useAnalytics } from '@hooks/useAnalytics';
import { BookOpen, Gear, SignIn, SignOut } from '@icons';

import LogoImage from '../LogoImage';

export const MiscNavbarItems: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation('common');
  const logout = useLogout();
  const { track } = useAnalytics();

  return (
    <>
      <MenuSection data-testid='settings-menu' title={t('profileMenu.settings')}>
        <NavLink key='settings' to='/settings/profile'>
          <DropdownMenuItem
            icon={<Gear weight='bold' />}
            title={t('profileMenu.settings')}
            variant='secondary'
            data-testid='settings-menu-item'
            onClick={() => track('navlink_clicked', { page: 'settings' })}
          />
        </NavLink>
        <NavLink key='knowledgeBase' to='/knowledge-base'>
          <DropdownMenuItem
            data-testid='settings-menu-knowledge-base'
            icon={<BookOpen weight='bold' />}
            title={t('profileMenu.knowledgeBase')}
            variant='secondary'
          />
        </NavLink>
      </MenuSection>
      <MenuSection>
        <DropdownMenuItem
          data-testid='settings-menu-logout'
          icon={<SignOut weight='bold' />}
          onClick={logout}
          title={t('menu.logout')}
          variant='secondary'
        />
      </MenuSection>
    </>
  );
};

interface GuestCertificateItemsProps {
  setIsModalShown: (value: React.SetStateAction<boolean>) => void;
}

export const GuestCertificateItems: React.FC<GuestCertificateItemsProps> = ({ setIsModalShown }) => {
  const { t } = useTranslation('common');

  return (
    <Menu startAdornment={<LogoImage />} align='right'>
      <Button endAdornment={<SignIn weight='bold' />} onClick={() => setIsModalShown(true)}>
        {t('signIn')}
      </Button>
    </Menu>
  );
};

export const LogoOnlyNavbar: React.FC = () => (
  <Menu align='center'>
    <LogoImage />
  </Menu>
);
