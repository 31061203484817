import { Account } from '@api/user/types';

export const getPortalContext = (accountType: Account['type']) => {
  const accountTypeToContext: { [x: string]: PortalContext } = {
    WeChooose: 'wechooose',
    WeChoooseCommmunity: 'connect',
  };

  return accountTypeToContext[accountType];
};
