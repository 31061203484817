import { defaultInstance } from '@api/instance';

import {
  CreateCustomer,
  Customer,
  CustomerCustomMetadata,
  CustomerOwnerReference,
  CustomerUser,
  UpdateCustomer,
  UpdateCustomerUserParams,
} from './types';

export const getCustomer = async (customerId: string) => {
  const { data } = await defaultInstance.get<Customer>(`/customers/${customerId}`);

  return data;
};

export const updateCustomer = async ({ id, ...body }: UpdateCustomer): Promise<Customer> => {
  const { data } = await defaultInstance.put<Customer>(`/customers/${id}`, body);

  return data;
};

export const updateCustomerMetadata = async ({ id, ...body }: CustomerMetadata & { metadata: CustomerCustomMetadata[] }): Promise<Customer> => {
  const { data } = await defaultInstance.put<Customer>(`/customers/${id}/metadata`, body);

  return data;
};

export const updateFacilitatedOrdersAutoApprove = async (customerId: string, autoApprove: boolean): Promise<unknown> => {
  const { data } = await defaultInstance.put<unknown>(`/customers/${customerId}/approvefacilitatedordersfromcurrentpartnership`, `${autoApprove}`);

  return data;
};

export const createCustomer = async (body: CreateCustomer) => {
  const { data } = await defaultInstance.post<Customer>(`/customers`, body);

  return data;
};

export const getAllUsers = async (customerId: string) => {
  const { data } = await defaultInstance.get<CustomerOwnerReference[]>(`/customers/${customerId}/users`);

  return data;
};

export const updateCustomerUser = async (customerId: string, userId: string, payload: UpdateCustomerUserParams) => {
  const { data } = await defaultInstance.put<CustomerUser>(`/customers/${customerId}/users/${userId}`, payload);

  return data;
};

export const deleteUserFromCustomer = async (customerId: string, userId: string) => {
  await defaultInstance.delete(`/customers/${customerId}/users/${userId}`);
};

export const deleteCustomer = async (customerId: string) => {
  await defaultInstance.delete(`/customers/${customerId}`);
};

export const getCustomerCurrencies = async (customerId: string) => {
  const { data } = await defaultInstance.get<string[]>(`/customers/${customerId}/currencies`);

  return data;
};

export const updateCustomerCurrency = async (customerId: string, currency: string) => {
  const { data } = await defaultInstance.put<string[]>(`/customers/${customerId}/currency`, currency, { headers: { 'Content-Type': 'text/plain' } });

  return data;
};
