import { OptionType } from '@chooose/ui';
import { Customer } from '@api/customer/types';
import { BulkItemDetails, Co2eAmount, GhgConversionStandard, ImpactUnit, LcfAmount, PaginationParams } from '@api/shared/types';
import { FILTER_PROPERTIES } from '@utils/PortfolioFiltersData';

export interface Order {
  id: string;
  createdDate: string;
  dueDate?: string; // @TODO 16460 return due date from API
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
  orderStatus: OrderStatus;
  choooseId: string;
  partnershipId: string;
  facilitatingPartnerships: string[];
  customerId: string;
  isGift: boolean;
  giftedToCustomerId: string;
  customerSubscriptionId: string;
  totalKilosCo2: number;
  totalCo2e: Co2eAmount;
  totalKilosCo2Avoided?: number;
  totalCo2eAvoided?: Co2eAmount;
  totalKilosCo2Offset?: number;
  totalCo2eOffset?: Co2eAmount;
  totalKilosCo2Rounded?: number;
  totalPriceXCustomerFee?: number;
  totalCustomerFee?: number;
  orderCustomerFee?: number;
  transactionCustomerFee?: number;
  totalVat?: number;
  totalExcise?: number;
  totalSalesTax?: number;
  totalPriceXVat?: number;
  taxes: TaxAmount[];
  totalTax?: number;
  totalPriceXTax?: number;
  totalPrice: number;
  co2Totals: Co2CompensationTotals;
  items: OrderItem[];
  currency: string;
  timestamp: string;
  reference: string;
  category: string;
  costCenter: string;
  poNumber: string;
  relations: OrderRelation[];
  metadata: OrderMetadata;
  payment: OrderPayment;
  supplyStatus: OrderSupplyStatus;
  retirements: OrderRetirementReference[];
  comment: string;
  description: string;
  createdByUser: UserPublicBio;
  attribution: OrderAttribution;
  attachments: Attachment[];
  createdDateFormatted: string;
  createdDateTimeFormatted: string;
  portfolioId: string;
  portfolioName: string;
  createdDay: string;
  purchaseCertificateId: string;
  facilitated: FacilitatedOrder;
  virtualPortfolio: VirtualPortfolio;
  multiplier: OrderMultiplier;
  bonusPoints: OrderBonusPoints[];
  certificates: OrderCertificate[];
  footprints: Footprints;
  appliedFees: OrderFees;
  lcfCertificates: OrderLcfCertificates;
  compliance?: ComplianceDetails;
}

interface OrderLcfCertificates {
  safDraft: LcfCertificate;
  safFinal: LcfCertificate;
}

type LcfCertificateStatus = 'NotRequested' | 'InProgress' | 'Ready';

interface LcfCertificate {
  status: LcfCertificateStatus;
  canBeRequested: boolean;
}

export const orderStatuses = [
  'Undefined',
  'Refund',
  'Retired',
  'Confirmed',
  'PendingConfirmation',
  'Paid',
  'PartiallyRetired',
  'Rejected',
  'Reserved',
  'TemporaryReserved',
  'ReservationFailed',
] as const;
type OrderStatus = (typeof orderStatuses)[number];

interface Footprints {
  totalCo2Kilos: number;
  deductions: ProcessedFootprintDeduction[];
  undeductedFootprint: CalculatedCo2Footprint;
}

interface ProcessedFootprintDeduction {
  kilosCo2: number;
  type: FlightFootprintDeductionType;
  footprintsNumber: number;
}

export interface CalculatedCo2Footprint {
  kilosCo2?: number;
  kilosCo2e?: number;
  lbsCo2?: number;
  lbsCo2e?: number;
  source?: string;
}

type CertificateDocumentType = 'CHOOOSE' | 'Registry';

interface OrderCertificate {
  createdDate?: string;
  type: CertificateDocumentType;
  title: string;
  url: string;
}

interface OrderBonusPoints {
  partnershipId: string;
  points: number;
  membershipNumber: string;
  ruleId: string;
  createdDate?: string;
}

type OrderMultiplierMode = 'Multiplier' | 'Discount';

export interface OrderMultiplier {
  mode: OrderMultiplierMode;
  value: number;
}

interface FacilitatedOrder {
  isFacilitated: boolean;
  isPaidByPartner: boolean;
  periodStartDate: string;
  statistics: FacilitatedFlightStatistics;
  facilitationDate?: string;
  approvalDate?: string;
}

interface FacilitatedFlightStatistics {
  thisYearCustomerOffsetKilosCo2: number;
  thisYearPartnerOffsetKilosCo2: number;
  thisYearTotalOffsetKilosCo2: number;
  currentOrder: FacilitatedFlightOrderStatistics;
  previousOrder: FacilitatedFlightOrderStatistics;
}

type AttachmentType = 'CarBatch' | 'FlightBatch' | 'MultiCustomerFlightBatch' | 'AirFreightBatch' | 'MultiCustomerAirFreightBatch';

interface Attachment {
  storageBlobName: string;
  type: AttachmentType;
  fileUrl: string;
}

interface UserPublicBio {
  name: string;
  pictureUrl?: string;
}

interface OrderAttribution {
  co2: Co2Attribution;
}
interface Co2Attribution {
  owners: AttributionAgent[];
  payer: AttributionAgent;
  facilitators: AttributionAgent[];
}

interface AttributionAgent {
  userId?: string;
  customerId?: string;
  partnershipId?: string;
  share?: number;
  kilosCo2?: number;
  assignmentId?: string;
}

interface OrderRetirementReference {
  supplyTransactionId: string;
  projectId: string;
  vintage: string;
  supplyId: string;
  amount: SupplyAmount;
  certificateBlobName: string;
  registryCertificateUrl: string;
  registryUrl: string;
  registryRetirementDate?: string;
  isCertificateDownloaded?: boolean;
}

type OrderSupplyStatus = 'Unassigned' | 'Reserved' | 'Booked' | 'PartiallyRetired' | 'Retired' | 'Void';

export const paymentStatuses = {
  proposed: 'Proposed', // when order is created by partner for a customer, but not confirmed by them
  draft: 'Draft', // not paid, not ready to be paid, because billing period is not closed
  open: 'Open', // ready to be paid
  paid: 'Paid',
  void: 'Void', // when invoice not paid on time / stripe sends "cancel" action, or when customer / bill is closed (voided) manually
} as const;
type PaymentStatus = (typeof paymentStatuses)[keyof typeof paymentStatuses];

interface OrderPayment {
  billId?: string;
  type: string;
  customerPaymentMethodId?: string;
  status: PaymentStatus;
  isRefund: boolean;
  isRefunded: boolean;
  paymentDate?: string;
  settlement: OrderSettlement;
}

interface OrderSettlement {
  isSettled: boolean;
  settlementAmount?: number;
  settlementAmountVat?: number;
  settlementCurrency?: string;
  settlementDate?: string;
}

interface OrderMetadata {
  customer?: OrderMetadataCustomer;
  order?: OrderMetadataOrder;
  car?: OrderMetadataCar;
  flight?: OrderMetadataFlight;
  event?: OrderMetadataEvent;
  products?: OrderMetadataProduct[];
  custom?: OrderMetadataCustom[];
  gift?: OrderMetadataGift;
}

interface OrderMetadataCustomer {
  lastName?: string;
  firstName?: string;
  name?: string;
  email?: string;
  gender?: string;
  nationality?: string;
  residentCountry?: string;
  ageRangeFrom?: number;
  ageRangeTo?: number;
  language?: string;
}

interface OrderMetadataOrder {
  channel?: string;
  customerCurrency?: string;
  customerVat?: number;
  customerPrice?: number;
  customerPaymentMethodId?: string;
}

interface OrderMetadataCar {
  area?: string;
  year?: number;
  make?: string;
  model?: string;
  derivative?: string;
  fuelType?: string;
  cars?: number;
  distanceKm?: number;
}

interface OrderMetadataFlightLeg {
  fromAirport?: string;
  toAirport?: string;
  kilosCo2?: number;
  travelClass?: string;
  distanceKm?: number;
  airline?: string;
  flightNumber?: string;
  travelersCount?: number;
  travelPurpose?: string;
}

interface OrderMetadataFlight extends OrderMetadataFlightLeg {
  fromCity?: string;
  toCity?: string;
  fromCountry?: string;
  toCountry?: string;
  flightLegs?: OrderMetadataFlightLeg[];
  flights?: number;
  destinations?: number;
  passengers?: number;
  radiativeForcingFactor?: number;
}

interface OrderMetadataGift {
  startDate?: Date;
  endDate?: Date;
  country?: string;
  plan?: string;
}

interface OrderMetadataEvent {
  personCount?: number;
  country?: string;
  days?: number;
  flightLength?: string;
  flightTravelersCount?: number;
  flightRoundTrip?: boolean;
}

interface OrderMetadataProduct {
  type?: string;
  brand?: string;
  model?: string;
  variation?: string;
  units?: number;
}

interface OrderMetadataCustom {
  displayName: string;
  internalName: string;
  value: string | Date;
}

interface OrderRelation {
  orderId: string;
  relationType: OrderRelationType;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

type OrderRelationType = 'IsRefundOf' | 'IsRefundedBy';

export type OrderItemType = 'Impact';
export type Impact = 'None' | 'Co2' | 'Lcf';

interface OrderItemPortfolio {
  type: string;
  impact: string;
  currency: string;
  childPortfolios: VirtualPortfolioChildPortfolio[];
}

export interface OrderItem {
  type: OrderItemType;
  impact: Impact;
  impactUnit?: ImpactUnit;
  offsetImpactTransactionId: string;
  footprintImpactTransactionId: string;
  portfolioId?: string;
  allocations: OrderItemProjectAllocation[];
  multiplierOffset: OrderItemPartnershipOffset;
  portfolioName: string;
  kilosCo2?: number;
  portfolio?: OrderItemPortfolio;
  co2e: Co2eAmount;
  lcf?: SimpleLcfAmount;
  estimatedCo2e?: Co2eAmount;
  kilosCo2Rounded: number;
  kilosCo2Avoided?: number;
  co2eAvoided: Co2eAmount;
  kilosCo2Offset?: number;
  co2eOffset: Co2eAmount;
  price: number;
  priceXCustomerFee?: number;
  customerFee?: number;
  vat?: number;
  priceXVat?: number;
  taxes: TaxAmount[];
  tax?: number;
  priceXTax?: number;
  details: ItemDetails;
  feeFactors: ItemFeeFactors;
  appliedFees: ItemFees[];
  taxFactors?: ItemTaxFactors;
}

interface SimpleLcfAmount {
  kilos?: number;
  liters?: number;
}

interface OrderItemPartnershipOffset {
  partnershipId: string;
  co2e: Co2eAmount;
  type: FlightFootprintDeductionType;
  projectId: string;
  offsetImpactTransactionId: string;
}
type FlightFootprintDeductionType = 'ETS' | 'AlreadyOffset';

export interface OrderItemProjectAllocation {
  portfolioProjectId: string;
  bookings: SupplyTransactionReference[];
  amount: SupplyAmount;
  offsetImpactTransactionId: string;
  kilosCo2: number;
}

interface SupplyTransactionReference {
  supplyTransactionId: string;
  amount: SupplyAmount;
}

interface SupplyAmount {
  description: string;
  co2e: Co2eAmount;
  lcf: LcfAmount;
  lcfConversion: LcfCo2eConversionParameters;
  units: number;
}

interface LcfCo2eConversionParameters {
  lcfCarbonIntensityGramsPerMj: number;
  lcfLhvMjPerKg: number;
  ghgConversionStandard: GhgConversionStandard;
  jetFuelLhvMjPerKg: number;
  jetFuelTtwGramsCo2PerMj: number;
  jetFuelTtwGramsCh4PerMj: number;
  jetFuelTtwGramsN2oPerMj: number;
  jetFuelWttFactor: number;
}

export interface ItemDetails {
  person?: PersonItemDetails;
  event?: EventItemDetails;
  bulk?: BulkItemDetails;
  car?: CarItemDetails;
  bus?: TransportItemDetails;
  train?: TransportItemDetails;
  flight?: FlightItemDetails;
  passengerFlight?: PassengerFlightItemDetails;
  airFreight?: AirFreightItemDetails;
  accommodation?: AccommodationDetails;
  meeting?: MeetingDetails;
  shipping?: ShippingItemDetails;
}

interface PersonItemDetails {
  category: string;
  countryCode: string;
  countryName: string;
  plan: MultiplierPlan;
  days?: number;
  months?: number;
  years?: number;
  personCount: number;
  groupType?: PersonGroupType;
}

type MultiplierPlan = 'ClimatePositive' | 'ClimateChampion' | 'ClimateHero';
type PersonGroupType = 'Individual' | 'Couple' | 'Family';

interface EventItemDetails {
  personCount: number;
  countryCode: string;
  countryName: string;
  days: number;
  participantType: EventParticipantType;
  plan: MultiplierPlan;
}

export type EventParticipantType = 'Participant' | 'DigitalParticipant' | 'Speaker' | 'Crew';

interface CarItemDetails {
  cars: number;
  distanceKm?: number;
  area?: string;
  year?: number;
  make?: string;
  model?: string;
  derivative?: string;
  fuelType?: string;
}
interface TransportItemDetails {
  travelClass: string;
  trips: number;
  passengers: number;
  roundTrip: boolean;
  routeLegs: TransportRouteDetails[];
}

interface TransportRouteDetails {
  fromCity?: string;
  fromCountry?: string;
  toCity?: string;
  toCountry?: string;
  distanceKm?: number;
}

interface FlightItemDetails {
  travelClass: string;
  flights: number;
  passengers: number;
  roundTrip: boolean;
  routeLegs?: FlightRouteDetails[];
  distance?: FlightDistanceDetails;
  cargoKilos?: number;
  cargoType?: CargoType;
}

interface FlightRouteDetails {
  fromAirportCode?: string;
  fromCityName?: string;
  fromCountryName?: string;
  toAirportCode?: string;
  toCityName?: string;
  toCountryName?: string;
  distanceKm?: number;
}

interface FlightDistanceDetails {
  flightLength?: string;
  distanceKm?: number;
}

type CargoType = 'Unknown' | 'Belly' | 'Freight';

export interface PassengerFlightItemDetails {
  travelClass?: string;
  flights?: number;
  passengers?: number;
  roundTrip?: boolean;
  routeLegs?: FlightRouteDetails[];
  distance?: FlightDistanceDetails;
}

interface AirFreightItemDetails {
  flights: number;
  roundTrip: boolean;
  routeLegs?: FlightRouteDetails[];
  distance?: FlightDistanceDetails;
  cargoKilos?: number;
  cargoType?: CargoType;
}

interface AccommodationDetails {
  days: number;
  rooms: number;
  description?: string;
  countryCode?: string;
  countryName?: string;
}

interface MeetingDetails {
  hours: number;
  squareMeters: number;
  description?: string;
  countryCode?: string;
  countryName?: string;
}

interface ShippingItemDetails {
  activity: string;
  type: string;
  size: string;
  weight: number;
  lca: LifeCycleAssessment;
  distanceKm: number;
  cargoCo2?: number;
}

type LifeCycleAssessment = 'WTT' | 'TTW' | 'WTW';

export interface OrderFees {
  source?: FeeSource;
  currency?: string;
  transaction?: TransactionFee;
  order?: TransactionFee;
}

export interface ItemTaxFactors {
  airports?: string[];
  customerCountry?: string;
  customerCountrySubdivision?: string;
  customerType?: Customer['type'];
}

export interface ItemFeeFactors {
  flightPax?: number;
  flightDistanceKm?: number;
  flightSegments?: number;
  eventFlightPax?: number;
  eventPersons?: number;
  employeeBenefitMonthlyPersons?: number;
  carMonthlyCars?: number;
  monthlyPersonalLifestylePersons?: number;
  monthlyPersonalCars?: number;
  monthlyPersonalPets?: number;
  bulkCo2?: boolean;
  train?: boolean;
  bus?: boolean;
  accommodation?: boolean;
  car?: boolean;
  flight?: boolean;
  bulkShipping?: boolean;
  shipping?: boolean;
  percentageBulkShipping?: boolean;
  airFreight?: boolean;
  percentageBulkCo2?: boolean;
}

export interface ItemFees {
  source?: FeeSource;
  currency?: string;
  portfolioId?: string;
  weight?: number;
  item?: ItemFee;
}

export interface ItemFee {
  feePerFlightPax?: number;
  tieredFlightFees?: TieredItemFlightFee[];
  feePerEventFlightPax?: number;
  feePerEventPerson?: number;
  feePerEmployeeBenefitMonthlyPerson?: number;
  feePerCarMonthlyCar?: number;
  feePerMonthlyPersonalLifestylePersons?: number;
  feePerMonthlyPersonalCars?: number;
  feePerMonthlyPersonalPets?: number;
  bulkCo2Fees?: BulkCo2Fee[];
  feePerTrainItem?: number;
  feePerTrainItemPercentage?: number;
  feePerBusItem?: number;
  feePerBusItemPercentage?: number;
  feePerAccommodationItem?: number;
  feePerAccommodationItemPercentage?: number;
  feePerAccommodationRoomDay?: number;
  feePerCarItem?: number;
  feePerCarItemPercentage?: number;
  feePerFlightItem?: number;
  feePerFlightItemPercentage?: number;
  bulkCo2ShippingFee?: BulkCo2Fee[];
  shippingPercentageFee?: number;
  airFreightPercentageFee?: number;
  percentageBulkCo2Fee?: PercentageBulkCo2Fee[];
  fixedFee?: number;
  defaultPercentageFee?: number;
}

interface TaxAmount {
  type: 'Vat' | 'Excise';
  name: string;
  countryCode: string;
  countrySubdivisionCode: string;
  amount: number;
  percentageRate: number;
}

type FeeSource = 'Custom' | 'Partnership' | 'PartnershipPortfolio' | 'Customer' | 'CustomerPortfolio';

interface TransactionFee {
  fixedFee?: number;
  percentageFee?: number;
}

interface TieredItemFlightFee {
  distanceKmFloor?: number;
  minimumFeePerFlightPax?: number;
  minimumPercentageFee?: number;
}

interface BulkCo2Fee {
  tonnesCo2Floor?: number;
  feePerTonne?: number;
}

interface PercentageBulkCo2Fee {
  tonnesCo2Floor?: number;
  percentageFeePerTonne?: number;
}

interface Co2CompensationTotals {
  byCategory: Co2CompensationCategory[];
  bySubCategory: Co2CompensationSubCategory[];
}

interface Co2CompensationCategory {
  key: ProjectCategory;
  kilosCo2: number;
  co2e: Co2eAmount;
}

interface Co2CompensationSubCategory {
  key: ProjectSubCategory;
  kilosCo2: number;
  co2e: Co2eAmount;
}

type ProjectCategory =
  | 'Undefined'
  | 'NatureBased'
  | 'Tech'
  | 'Community'
  | 'RenewableEnergy'
  | 'Saf'
  | 'CapAndTrade'
  | 'TreePlanting'
  | 'IndustrialProcess'
  | 'BiomassConversion';

type ProjectSubCategory =
  | 'Undefined'
  | 'BlueCarbon'
  | 'Soil'
  | 'Forestry'
  | 'AgriculturalLandManagement'
  | 'EcoReservation'
  | 'DirectAir'
  | 'Mineralization'
  | 'Biomass'
  | 'Cookstoves'
  | 'WaterTreatment'
  | 'WindFarms'
  | 'Hydroplants'
  | 'SolarFarms'
  | 'LandfillGas'
  | 'BiomassEnergy'
  | 'Saf'
  | 'EUA'
  | 'EUAA'
  | 'TreePlanting'
  | 'HFCCFC'
  | 'BioChar'
  | 'Beccs'
  | 'BiobasedConstruction';

export interface OrderExpandParams {
  includeVirtualPortfolio?: boolean;
  includeStats?: boolean;
  includeAttachments?: boolean;
  includeDeleted?: boolean;
}

export type OrdersParams = {
  page?: number;
  category?: string;
  fromCreatedDate?: string;
  toCreatedDate?: string;
  fromModifiedDate?: string;
  toModifiedDate?: string;
  paymentStatus?: Order['payment']['status'];
  supplyStatus?: Order['supplyStatus'][];
  complianceAgreementType?: string;
  complianceAgreementId?: string;
  complianceAgreementYear?: string;
} & PaginationParams;

export interface MultipleOrdersResponse extends OrderResponse {
  currency: string;
  passengers?: number;
  totalKilosCo2: number;
  totalPrice: number;
}

export interface OrderResponse {
  orderId: string;
  order: OrderLegacy<OrderFootprint>;
  stripePaymentIntentId?: string;
  stripeClientSecret?: string;
  stripePaymentMethodId?: string;
  claimCode?: string;
  inviteId?: string;
  fileUrl?: string;
  billId?: string;
  orderIds?: string[];
  footprintIds?: string[];
  orders?: Partial<MultipleOrdersResponse[]>;
  jobId?: string;
}

export interface Metadata {
  flight?: {
    fromAirport?: string;
    toAirport?: string;
    fromCity?: string;
    toCity?: string;
    fromCountry?: string;
    toCountry?: string;
    travelersCount: number;
    distanceKm?: number;
    roundtrip: boolean;
    flights: number;
    passengers: number;
    travelClass: TravelClass;
  };
  order?: {
    channel: string;
  };
  event?: {
    personCount: number;
    country?: string;
    days: number;
    flightLength?: string;
    flightTravelersCount?: number;
    flightRoundTrip?: boolean;
  };
  custom?: {
    internalName: string;
    value: string | Date;
  }[];
  gift?: {
    country: string;
    startDate: string;
    endDate: string;
    plan: string;
  };
}

export type FootprintsOrderFilters = Omit<EmissionsMetadata, 'status'> & { status: 'Incomplete' };

export interface GetAllOrdersResponse<T> {
  totalCount: number;
  offset: number;
  limit: number;
  count: number;
  items: T[];
  filters?: string[];
}

export interface GetFootprintsForOrderRequest extends PaginationParams {
  grouped?: boolean;
  fromDate?: string;
  toDate?: string;
  type?: string;
}

export type EmissionsResponse<T> = GetAllOrdersResponse<T> & EmissionsMetadata;

export interface GetAllOrdersRequest extends PaginationParams {
  category?: string;
  fromCreatedDate?: string;
  toCreatedDate?: string;
  'payment.status'?: string;
  isfacilitated?: boolean;
}

export interface PortfolioPrice {
  portfolioId: string;
  pricePerTonne: number;
}

export interface FootprintsOrderParams {
  currency: string;
  portfolioId?: string | null;
  portfolio?: VirtualPortfolio;
  multiplier?: Pick<Multiplier, 'value' | 'mode'>;
  footprints?: FootprintsOrderItem[];
  filter?: FootprintsOrderFilters;
}

type FootprintsOrderItem = {
  year?: number;
  month?: number;
  footprintId: string;
};

export type ReserveQuoteOrderParams = {
  quoteId: string;
  temporarySupplyBooking: boolean;
  quoteFootprints?: CompensatedFootprint[];
  currency: string;
  category: string;
};

export interface CreateOrderParams {
  description: string;
  currency: string;
  category: string;
  costCenter: string;
  poNumber: string;
  createdDate?: string;
  items: CreateOrderItem[];
  attachments?: Attachment[];
  multiplier?: OrderMultiplier;
  quoteFootprints?: CompensatedFootprint[];
  applyDeductions?: boolean;
  metadata: OrderMetadata;
  gift?: boolean;
}
export interface ConfirmOrderParams {
  id: string;
  metadata: Pick<OrderMetadata, 'custom'>;
  gift?: boolean;
  currency: string;
  poNumber?: string;
  costCenter?: string;
  description: string;
}
export interface CreateOrderItem {
  type: OrderItemType;
  impact: Impact;
  impactUnit?: ImpactUnit;
  portfolioId: string | null;
  price: number;
  customerFee?: number;
  vat?: number;
  priceXVat?: number;
  priceXCustomerFee?: number;
  kilosCo2?: number;
  lcf?: SimpleLcfAmount;
  details?: ItemDetails;
  feeFactors?: ItemFeeFactors;
  taxFactors?: TaxFactors;
  virtualPortfolios?: CreateOrderItemVirtualPortfolio[];
  portfolio?: Portfolio;
}

interface CreateOrderItemVirtualPortfolio {
  weight: number;
  portfolioId: string;
}

export interface CreatedQuote {
  id: string;
  guid: string;
  partnershipId: string;
  expirationDate: string;
  kilosCo2?: number;
  price?: number;
  currency: string;
  portfolioPriceQuote: Quote;
  status: QuoteStatus;
  channel?: string;
  reference: string;
  facilitatingPartnerships: string[];
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy?: string;
}

type QuoteStatus = 'Unknown' | 'Valid' | 'Accepted' | 'Abandoned' | 'Canceled';

export interface FootprintsCreatedQuote extends CreatedQuote {
  footprints?: {
    itemIndex: number;
    footprintId: string;
  }[];
}

export interface Quote {
  quoteId: string;
  currency: string;
  totalPrice: number;
  totalPriceXCustomerFee: number;
  totalCustomerFee: number;
  orderCustomerFee: number;
  transactionCustomerFee: number;
  totalVat?: number;
  totalExcise?: number;
  totalSalesTax?: number;
  totalPriceXVat?: number;
  taxes: TaxAmount[];
  totalTax?: number;
  totalPriceXTax?: number;
  totalImpactCo2Formatted: FormattedImpact;
  totalImpactCo2FormattedImperial: FormattedImpact;
  totalKilosCo2: number;
  totalCo2e: Co2eAmount;
  totalKilosCo2Avoided?: number;
  totalCo2eAvoided?: Co2eAmount;
  totalKilosCo2Offset?: number;
  totalCo2eOffset?: Co2eAmount;
  items: QuoteItem[];
  estimatedBonusPoints: OrderBonusPoints[];
  potentialBonusPoints: OrderBonusPoints[];
  appliedFees: OrderFees;
  footprints?: {
    itemIndex: number;
    footprintId: string;
  }[];
  footprintDetails: Footprints;
  complianceAgreementId?: string;
}

interface FormattedImpact {
  amount: number;
  unit: string;
  unitAbbreviation: string;
  amountWithUnit: string;
  amountWithUnitAbbreviation: string;
}

export interface VirtualPortfolio {
  portfolioId?: string;
  id?: string;
  weight?: number;
  childPortfolios?: VirtualPortfolioChildPortfolio[];
  supplyFilter?: SupplyFilter;
}

export interface SupplyFilter {
  eligibleComplianceAgreementPhase?: string;
  vintage_in?: string[];
  vintage_not_in?: string[];
  producer?: string[];
  producer_in?: string[];
  producer_not_in?: string[];
  vendor?: string[];
  vendor_in?: string[];
  vendor_not_in?: string[];
  sustainabilityCertificationScheme?: string[];
  sustainabilityCertificationScheme_in?: string[];
  sustainabilityCertificationScheme_not_in?: string[];
}

export interface VirtualPortfolioChildPortfolio {
  id?: string;
  weight?: number;
  supplyFilter?: SupplyFilter;
  price?: number;
}

export interface QuoteItem {
  type: OrderItemType;
  impact: Impact;
  impactUnit?: ImpactUnit;
  portfolioId?: string;
  portfolio?: VirtualPortfolio;
  kilosCo2?: number;
  co2e: Co2eAmount;
  kilosCo2Avoided?: number;
  co2eAvoided: Co2eAmount;
  kilosCo2Offset?: number;
  co2eOffset: Co2eAmount;
  price: number;
  priceXCustomerFee?: number;
  customerFee?: number;
  vat?: number;
  priceXVat?: number;
  taxes: TaxAmount[];
  tax?: number;
  priceXTax?: number;
  details?: ItemDetails;
  feeFactors: ItemFeeFactors;
  taxFactors: ItemTaxFactors;
  appliedFees: ItemFees[];
  priceRange?: {
    priceFrom: number;
    priceTo: number;
  };
}

export interface ConfirmOrderResponse {
  orderId: string;
  billId: string;
  order: Order;
}

interface ChildPortfolio {
  id: string;
  weight: number;
  supplyFilter?: {
    vintage_in: string[];
  };
}

interface PortfolioProject {
  projectId: string;
  weight: number;
  isSaf?: boolean;
  supplyType: SupplyType;
  project: ProjectDetails;
}

interface CountryDetails {
  codeIso2: string;
  codeIso3: string;
  continentCode: string;
  name: string;
  region: string;
  subRegion: string;
}

interface PriceRange {
  priceFrom: number;
  priceTo: number;
}

interface Vintage {
  vintage: string;
  price?: number;
  priceRange?: PriceRange;
  totalTonnesCo2Available: number;
}

export interface SanityImage {
  isMainImage: boolean;
  url: string;
  imageId: string;
  assetId: string;
  label?: string;
  originalFileName: string;
  sanityPath: string;
}

interface ProjectDetails {
  title: string;
  subtitle: string;
  impact: Impact;
  country: string;
  city: string;
  totalTonnesCo2Available: number;
  projectOwner: string;
  projectFactSheet: string;
  isSaf?: boolean;
  supplyType: SupplyType;
  lcfType?: LcfType;
  impactType: string;
  category: string;
  categoryTitle?: string;
  subCategory: string;
  subCategoryTitle?: string;
  availableVintages?: string[];
  countryDetails: CountryDetails;
  sanityId: string;
  sanitySlug: string;
  id: string;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
  supplySummary?: {
    lcfProducers: string[];
    lcfUptakeLocations: string[];
    lcfFeedstocks: string[];
    lcfOrigins: string[];
  };
  vintages: Vintage[];
}

export interface Portfolio {
  title: string;
  subtitle: string;
  description: string;
  theme: string;
  impact: Impact;
  pricePerTonneCo2: number;
  currency: string;
  type?: string;
  childPortfolios?: ChildPortfolio[];
  projects: PortfolioProject[];
  estimatedCustomerPriceCurrency?: string;
  estimatedCustomerPricePerTonne?: number;
  estimatedCustomerPricePerTonneXTax?: number;
  baseCustomerPriceCurrency?: string;
  baseCustomerPricePerTonne?: number;
  containsSaf?: boolean;
  salesPricesPerTonneCo2?: number;
  default: boolean;
  vintages: Vintage[];
  totalTonnesCo2Available: number;
  sanityImages: SanityImage[];
  sanityId: string;
  sanitySlug: string;
  id: string;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

type TransformedPricePerTonne = {
  main: number;
  from?: number;
  to?: number;
};

export type TransformedVintage = {
  weight?: number;
  weightRounded?: string;
  totalCo2Unit: string;
  totalCo2Available: string;
  totalTonnesCo2Available: number;
  totalCo2AvailableInKilos: number;
  pricePerTonne: TransformedPricePerTonne;
  supplyFilter?: SupplyFilter;
};

export type TransformedPortfolio = {
  categories?: {
    title: string;
    slug: string;
  }[];
  city: string;
  country: string;
  totalCo2Unit: string;
  totalCo2Available: string;
  totalTonnesCo2Available: number;
  totalCo2AvailableInKilos: number;
  portfolioId: string;
  portfolioSlug: string;
  pricePerTonne: TransformedPricePerTonne;
  projectSlug?: string;
  title: string;
  url: string;
  weight: number;
  vintages: TransformedVintage[];
  isSaf: boolean;
  isSinglePortfolio: boolean;
};

type OrderItemPortfolioPricePerTonneForCustomer = Pick<
  OrderItem,
  'type' | 'impact' | 'kilosCo2' | 'feeFactors' | 'taxFactors' | 'portfolio' | 'portfolioId'
>;

export interface OrderPortfolioPricePerTonneForCustomerParams {
  currency: string;
  category: string;
  items: OrderItemPortfolioPricePerTonneForCustomer[];
}

export type PortfolioFiltersData = Partial<Record<(typeof FILTER_PROPERTIES)[number], OptionType[]>>;
export type TravelClass = 'economy' | 'premium' | 'business' | 'first';

export interface Job {
  id: string;
  parentId: string;
  partnershipId: string;
  startedDate?: Date;
  failedDate?: Date;
  succeededDate?: Date;
  abandonedDate?: Date;
  status: JobStatus;
  description: string;
  messages: JobMessage[];
  progressPercentage: number;
  itemsProcessed: number;
  affectedObjects: JobAffectedObject[];
  summary: JobSummary;
  childJobs: ChildJob[];
}

type JobStatus = 'Created' | 'Started' | 'Failed' | 'Succeeded' | 'Abandoned';
interface JobMessage {
  row?: number;
  timestamp: Date;
  type: JobMessageType;
  message: string;
}

type JobMessageType = 'Information' | 'Warning' | 'Error';
interface JobAffectedObject {
  row: number;
  customer?: JobAffectedObjectReference;
  user?: JobAffectedObjectReference;
  b2cUser?: JobAffectedObjectReference;
}

interface JobAffectedObjectReference {
  id: string;
  status: JobAffectedObjectStatus;
}
type JobAffectedObjectStatus = 'AlreadyExists' | 'Created';

interface JobSummary {
  footprints?: FootprintsSummary;
  processedFile?: ProcessedFileViewModel;
}

interface FootprintsSummary {
  totalCo2Kilos: number;
  count: number;
  storageBlobName?: string;
  footprintYears: number[];
  rowsCompensated: number;
  deductions: ProcessedFootprintDeduction[];
  undeductedFootprint: CalculatedCo2Footprint;
}

interface ProcessedFileViewModel {
  priceQuote: Quote;
  attachment: Attachment;
  attachmentTempUrl: string;
  rows: number;
  rowsCompensated: number;
  deductions: ProcessedFootprintDeduction[];
  undeductedFootprint: CalculatedCo2Footprint;
  details: ProcessedFileDetails;
}

interface ProcessedFileDetails {
  multiCustomerFlights: MultiCustomerFlightsDetails;
}

interface MultiCustomerFlightsDetails {
  customersCount: number;
}

interface ChildJob {
  id: string;
  status: JobStatus;
  description: string;
  childJobs: ChildJob[];
  progressPercentage: number;
}

export interface PerCapitaDetails {
  source: string;
  area: string;
  years: number;
  months: number;
  days: number;
  persons: number;
  applyTradeAdjustment: boolean;
  calculateForCustomer?: string;
}

export interface PerCapitaFootprint {
  countryCode: string;
  kilosCo2: number;
  kilosCo2e: number;
  years: number;
  months: number;
  days: number;
  lbsCo2: number;
  lbsCo2e: number;
  persons: number;
  source: string;
  totalDays: number;
  tradeAdjustmentFactor: number;
}
export interface ComplianceDetails {
  createdForComplianceAgreementId: string;
  type: string;
  phases: string[];
  year: number;
}
