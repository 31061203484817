import { Box } from '@chooose/ui';
import styled, { css } from 'styled-components';
import { usePartnershipSettings } from '@api/partnership/queries';
import ChoooseBlackLogo from '@assets/logos/black-logo.png';
import ChoooseWhiteLogo from '@assets/logos/white-logo.png';

const Wrapper = styled(Box)<{ $isWideLogo?: boolean }>`
  ${({ $isWideLogo }) => css`
    width: 100%;
    min-width: 100px;
    max-width: ${$isWideLogo ? '200px' : '140px'};
  `}
`;

const Image = styled.img<{ $isHighLogo?: boolean; $height?: string }>`
  ${({ $isHighLogo, $height }) => css`
    width: 100%;
    object-fit: contain;
    max-height: ${$isHighLogo ? 'none' : '100%'};
    height: ${$height ?? 'auto'};
  `}
`;

const LogoImage = () => {
  const { data: partnershipSettings } = usePartnershipSettings();
  const { logoUrl, lightLogoUrl, colors } = partnershipSettings;
  const colorScheme = colors?.navbarColorScheme?.toLowerCase() === 'dark' ? 'dark' : 'light';
  const partnershipLogoUrl = colorScheme === 'dark' ? lightLogoUrl : logoUrl;
  const defaultLogo = colorScheme === 'dark' ? ChoooseWhiteLogo : ChoooseBlackLogo;

  // TODO: move to settings
  const isWideLogo = import.meta.env.REACT_APP_WIDE_LOGO;
  const isHighLogo = import.meta.env.REACT_APP_HIGH_LOGO;

  if (!partnershipSettings) {
    return null;
  }

  return (
    <Wrapper data-testid='logo-image-wrapper' $isWideLogo={isWideLogo}>
      <Image src={partnershipLogoUrl ?? defaultLogo} alt={'logo'} $isHighLogo={isHighLogo} $height={partnershipLogoUrl ? undefined : '18px'} />
    </Wrapper>
  );
};

export default LogoImage;
