import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Grid, Typography } from '@chooose/ui';
import { logoutOutOfApp } from '@components/AccountInitializer/getCurrentAccountAndContext';
import LogoImage from '@components/Navbar/LogoImage';

import * as s from './LogoutRedirect.styles';

interface LogoutRedirectProps {
  currentPartner?: string;
  redirectDelayTimeout?: number;
}

const partnerName = import.meta.env.REACT_APP_PARTNER_NAME;

export const LogoutRedirect = ({ currentPartner = '', redirectDelayTimeout = 30000 }: LogoutRedirectProps) => {
  const { t } = useTranslation('common');
  const handleRedirect = () => {
    logoutOutOfApp();
  };
  useEffect(() => {
    setTimeout(() => {
      handleRedirect();
    }, redirectDelayTimeout);
  });

  return (
    <s.PageWrapper>
      <s.Navbar>
        <LogoImage />
      </s.Navbar>
      <Grid align='center' justify='center'>
        <s.Card>
          <Flex gap='md' direction='column' align='center'>
            <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M32 24V10.6667H50.6667L56 17.3334L50.6667 24H32ZM32 24V37.3334M32 24H13.3333L8 30.6667L13.3333 37.3334H32M32 37.3334V53.3334M32 53.3334H21.3333M32 53.3334H42.6667'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='square'
              />
            </svg>
            <Typography tag='h3'>{t('redirectHeader', { redirectToPartner: partnerName })}</Typography>
          </Flex>
          <Flex gap='md' direction='column'>
            <Typography data-testid='redirect-msg' variant='primary'>
              {t('redirectDesc', { currentPartner, redirectToPartner: partnerName })}
            </Typography>
            <Typography data-testid='redirect-msg-cd' variant='primary'>
              {t('redirectDescCd')}
            </Typography>
            <Button data-testid='redirect-btn' variant='primary' onClick={handleRedirect}>
              {t('redirect')}
            </Button>
          </Flex>
        </s.Card>
      </Grid>
    </s.PageWrapper>
  );
};
