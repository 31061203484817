import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, Typography } from '@chooose/ui';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ArrowCounterClockwise } from '@components/Icons';

import * as s from './RootErrorBoundary.styles';

interface RootErrorBoundaryProps extends WithTranslation {
  appInsights: ReactPlugin;
  children: React.ReactElement;
}

interface RootErrorBoundaryState {
  hasError: boolean;
  isAssetError: boolean;
}

class RootErrorBoundaryComponent extends React.Component<RootErrorBoundaryProps, RootErrorBoundaryState> {
  constructor(props: RootErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, isAssetError: false };
  }

  static getDerivedStateFromError(error: Error) {
    const assetError = error.message?.includes('Failed to fetch dynamically imported module');

    return { hasError: true, isAssetError: assetError };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.appInsights.trackException({
      error: error,
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: errorInfo,
    });
  }

  reload() {
    if (window.zE) {
      window.zE('webWidget', 'close');
    }

    if (this.state.isAssetError) {
      this.setState({ hasError: false, isAssetError: false });
      window.location.reload();

      return;
    }

    this.setState({ hasError: false, isAssetError: false });
  }

  render() {
    if (this.state.hasError) {
      if (window.zE) {
        window.zE('webWidget', 'open');
      }

      return (
        <s.RootErrorBoundaryWrapper>
          <div>
            <s.Heading data-testid='root-error-header' variant='display'>
              {this.props.t('error.boundary.oops')}
            </s.Heading>
            <Typography data-testid={`root-error-label`} variant='secondary'>
              {this.state.isAssetError ? this.props.t('error.outdatedAppVersionMessage') : this.props.t('error.boundary.description')}
            </Typography>
          </div>
          <Button
            data-testid='root-error-button'
            startAdornment={<ArrowCounterClockwise size={16} weight='bold' />}
            size='lg'
            onClick={() => this.reload()}>
            {this.state.isAssetError ? this.props.t('refresh') : this.props.t('error.boundary.retry')}
          </Button>
        </s.RootErrorBoundaryWrapper>
      );
    }

    return <>{this.props.children}</>;
  }
}

export const RootErrorBoundary = withTranslation('common')(RootErrorBoundaryComponent);
