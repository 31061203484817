import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@chooose/ui';
import styled, { css } from 'styled-components';
import { ReactComponent as Oops } from '@assets/svgs/Oops.svg';

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    padding-top: ${theme.spacing.md};
  `}
`;

const OopsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.spacing.xxl};
    width: 100%;
    max-width: 360px;

    path {
      fill: black;
    }
  `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 540px;
`;

const StyledOops = styled(Oops)`
  width: 100%;
  height: 100%;
`;

const Info = styled(Typography)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.xl};
    text-align: center;
    max-width: 567px;
  `}
`;

const isProduction = import.meta.env.PROD;

interface FallbackProps {
  error: Error;
  resetError: () => void;
}

export const ErrorFallback: FC<React.PropsWithChildren<FallbackProps>> = ({ error, resetError }) => {
  const { t } = useTranslation('common');
  const isMissingAssetError = error.message?.includes('Failed to fetch dynamically imported module');

  if (isMissingAssetError) {
    return (
      <Wrapper>
        <Info data-testid='error-fallback-info' variant='secondary'>
          {t('error.outdatedAppVersionMessage')}
        </Info>
        <Button data-testid='error-fallback-btn' onClick={() => window.location.reload()}>
          {t('refresh')}
        </Button>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <OopsWrapper>
        <StyledOops />
      </OopsWrapper>
      <TextWrapper>
        <Info data-testid='unexpected-error-msg' variant='secondary'>
          {t('unexpectedErrorMessage')}
        </Info>
        {!isProduction && <Info variant='secondary'>{error?.message}</Info>}
        <Button data-testid='unexpected-error-btn' onClick={resetError}>
          {t('unexpectedErrorButton')}
        </Button>
      </TextWrapper>
    </Wrapper>
  );
};
